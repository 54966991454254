<template>
	<v-card max-width="800" class="mx-auto mt-5" :loading="loading || loadingParent" rounded="xl">
		<v-card-title>
			<span>{{ $t('invoices.invoiceAddress') }}</span>
			<v-spacer />
			<v-btn icon color="warning" @click="switchEditMode()">
				<v-icon>{{ editMode ? 'mdi-close' : 'mdi-pencil' }}</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<template v-if="editMode">
				<v-form ref="form" v-model="valid" class="mb-4">
					<v-row>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								v-model="invoice.attention"
								:label="$t('invoices.attention')"
								placeholder="John Doe, HR"
								:rules="rules.attention"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="8">
							<v-text-field
								v-model="invoice.companyName"
								:label="$t('invoices.companyName')"
								placeholder="Froteam Engineering S.L."
								:rules="rules.companyName"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="4">
							<v-text-field
								v-model="invoice.taxCode"
								:label="$t('invoices.taxCode')"
								:rules="rules.taxCode"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								v-model="invoice.email"
								type="email"
								:label="$t('auth.email')"
								:rules="rules.email"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								v-model="invoice.address1"
								:label="$t('invoices.address1')"
								:rules="rules.address1"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								v-model="invoice.address2"
								:label="$t('invoices.address2')"
								:rules="rules.address2"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-text-field
								v-model="invoice.city"
								:label="$t('invoices.city')"
								:rules="rules.city"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-text-field
								v-model="invoice.postCode"
								:label="$t('invoices.postCode')"
								:rules="rules.postCode"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-text-field
								v-model="invoice.province"
								:label="$t('invoices.province')"
								:rules="rules.province"
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-autocomplete
								v-model="invoice.country"
								:label="$t('invoices.country')"
								:items="availableCountries"
								item-text="name"
								item-value="key"
								:rules="rules.country"
								return-object
								required
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
					</v-row>
				</v-form>
				<div class="mx-auto d-flex align-center justify-center flex-wrap pt-0 pb-1">
					<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callUpdateCompanyInvoiceInfo()">
						{{ $t('settings.edit') }}
					</v-btn>
				</div>
			</template>
			<template v-else>
				<template v-if="invoice.companyName">
					<v-alert
						v-if="setupInfo.company.invoice && !setupInfo.company.invoiceVerified"
						border="top"
						elevation="8"
						type="warning"
						color="red darken-3"
						max-width="500"
						dark
						class="mx-auto text-uppercase text-center"
					>
						{{ $t('invoices.emailNotVerified') }}
					</v-alert>
					<v-row>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								:value="company.invoice.attention"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.attention')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="8">
							<v-text-field
								:value="company.invoice.companyName"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.companyName')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="4">
							<v-text-field
								:value="company.invoice.taxCode"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.taxCode')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field :value="company.invoice.email" outlined rounded hide-details="auto" class="pb-3" :label="$t('auth.email')" readonly>
								<template #append-outer>
									<v-chip large :color="setupInfo.company.invoiceVerified ? 'green' : 'red'">
										<v-icon>
											{{ setupInfo.company.invoiceVerified ? 'mdi-check-circle-outline' : 'mdi-close-circle' }}
										</v-icon>
										<span class="text-body-2">
											{{ setupInfo.company.invoiceVerified ? $t('users.verified') : $t('users.unverified') }}
										</span>
									</v-chip>
									<v-btn
										v-if="setupInfo.company.invoice && !setupInfo.company.invoiceVerified"
										color="primary"
										rounded
										@click="callSendVerifyInvoiceEmail()"
									>
										{{ $t('auth.resendEmail') }}
									</v-btn>
								</template>
							</v-text-field>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								:value="company.invoice.address1"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.address1')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12">
							<v-text-field
								:value="company.invoice.address2"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.address2')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-text-field :value="company.invoice.city" outlined rounded hide-details="auto" class="pb-3" :label="$t('invoices.city')" readonly />
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-text-field
								:value="company.invoice.postCode"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.postCode')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-text-field
								:value="company.invoice.province"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.province')"
								readonly
							/>
						</v-col>
						<v-col class="pt-1 pb-0" cols="12" lg="6" xs="12">
							<v-autocomplete
								:value="company.invoice.country"
								:items="availableCountries"
								item-text="name"
								item-value="company.invoice.key"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
								:label="$t('invoices.country')"
								return-object
								readonly
							/>
						</v-col>
					</v-row>
				</template>

				<template v-else>
					<div key="noItemsData" class="mx-auto">
						<div class="mt-2 mb-5 text-center">
							<v-icon size="128" role="img">
								mdi-package-variant
							</v-icon>
							<br />
							<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
						</div>
						<h4 class="m-4 text-center">
							We need the invoice information of your company in order to apply a subscription.
						</h4>
						<div class="text-center" style="width: 100%">
							<v-btn text small rounded color="warning" @click="switchEditMode()">
								{{ $t('settings.add') }}
							</v-btn>
						</div>
					</div>
				</template>
			</template>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyInvoices',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			invoice: {
				attention: '',
				companyName: '',
				email: '',
				address1: '',
				address2: '',
				postCode: '',
				city: '',
				province: '',
				country: null,
				taxCode: ''
			},
			rules: {
				attention: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.attentionMaxLength) || this.$t('rules.length', { length: this.attentionMaxLength })
				],
				companyName: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.companyNameMaxLength) || this.$t('rules.length', { length: this.companyNameMaxLength })
				],
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength })
				],
				address1: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.addressMaxLength) || this.$t('rules.length', { length: this.addressMaxLength })
				],
				address2: [(v) => (v && v.length <= this.addressMaxLength) || this.$t('rules.length', { length: this.addressMaxLength })],
				postCode: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.postCodeMaxLength) || this.$t('rules.length', { length: this.postCodeMaxLength })
				],
				city: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.cityMaxLength) || this.$t('rules.length', { length: this.cityMaxLength })
				],
				province: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.provinceMaxLength) || this.$t('rules.length', { length: this.provinceMaxLength })
				],
				country: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableCountries.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				taxCode: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.taxCodeMaxLength) || this.$t('rules.length', { length: this.taxCodeMaxLength })
				]
			}
		}
	},
	watch: {
		company(val) {
			if (val) this.invoice = this.company.invoice
		}
	},
	computed: {
		attentionMaxLength() {
			return 64
		},
		companyNameMaxLength() {
			return 255
		},
		emailMaxLength() {
			return 255
		},
		addressMaxLength() {
			return 255
		},
		postCodeMaxLength() {
			return 16
		},
		cityMaxLength() {
			return 64
		},
		provinceMaxLength() {
			return 64
		},
		taxCodeMaxLength() {
			return 64
		},
		...mapGetters({
			company: 'companies/company',
			availableCountries: 'data/availableCountries',
			setupInfo: 'user/setupInfo'
		})
	},
	created() {
		this.loading = true
		this.fetchAvailableCountries().then(() => {
			this.loading = false
		})
		this.invoice = this.company.invoice
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdateCompanyInvoiceInfo() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			let emailChanged = this.invoice.email != this.company.invoice.email
			this.updateCompanyInvoiceInfo({
				companyID: this.company.id,
				attention: this.invoice.attention,
				companyName: this.invoice.companyName,
				email: this.invoice.email,
				address1: this.invoice.address1,
				address2: this.invoice.address2,
				postCode: this.invoice.postCode,
				city: this.invoice.city,
				province: this.invoice.province,
				country: this.invoice.country,
				taxCode: this.invoice.taxCode
			}).then(({ success }) => {
				this.switchEditMode()
				this.loading = false
				this.fetchSetupInfo()
				if (success && emailChanged) this.$router.push({ name: 'SettingsInvoiceVerify' })
			})
		},
		callSendVerifyInvoiceEmail() {
			this.sendVerifyInvoiceEmail({ companyID: this.company.id }).then(({ success }) => {
				if (success) this.$router.push({ name: 'SettingsInvoiceVerify' })
			})
		},
		...mapActions('invoices', ['sendVerifyInvoiceEmail']),
		...mapActions('companies', ['updateCompanyInvoiceInfo']),
		...mapActions('data', ['fetchAvailableCountries']),
		...mapActions('user', ['fetchSetupInfo'])
	}
}
</script>
